import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from './lang/en.json'
import ar from './lang/ar.json'

import VueFeather from 'vue-feather';
import 'feather-icons'
Vue.use(VueFeather);

import { BootstrapVue } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
//api.myplugincom/widget/mykey.js';'

/*import VueSession from 'vue-session'
Vue.use(VueSession)*/

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)
// Or you can specify any other name and use it via this.$ls, this.$whatEverYouWant
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true //created computed members from your variable declarations
})

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7d' })

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";


import firebaseMessaging from './firebase'
try{
Vue.prototype.$messaging = firebaseMessaging
}catch(err){
    console.log(err)
}



import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)




import AOS from 'aos'
import 'aos/dist/aos.css'


import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

Vue.use(VueI18n)


Vue.config.productionTip = false

import imageZoom from 'vue-image-zoomer';
Vue.use(imageZoom);


var locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');


const i18n = new VueI18n({
    locale:'en',
    messages: {
        'en': en,
        'ar': ar,
    }
})

import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)


import "vue2-animate/dist/vue2-animate.min.css";


import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAl1hFUtULwFT_AjaThoN-eF-xgH_NYptw",
    },
    installComponents: true,
});

//zoom
import ProductZoomer from 'vue-product-zoomer'
Vue.use(ProductZoomer)

//share
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

document.dispatchEvent(new Event('render-event'));

new Vue({
    i18n,
    router,
    store,
    //LoadScript,
    mounted() {
        AOS.init()
       
      },
    render: h => h(App),
}).$mount('#app')